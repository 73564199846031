import React from "react";

function SEOLadingSection2() {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen gap-8 px-4 py-12 lg:flex-row sm:px-8 lg:px-40">
      <img
        src="/images/Side_Graphic.svg"
        alt="Bartender_Image"
        className="w-auto h-[15rem] lg:h-[25rem] max-lg:hidden"
      />
      <div className="max-w-3xl">
        <h1 className="text-2xl font-normal sm:text-3xl lg:text-4xl font-Righteous">
          Why Hiring the{" "}
          <span className="font-Righteous text-error">Right Talent</span>{" "}
          Matters?
        </h1>
        <p className="pt-3 text-sm font-poppins text-gray-1 sm:text-base">
          As Charleston's premier platform for hiring top-quality bartenders, we
          simplify the process of connecting you with skilled professionals who
          can enhance your establishment's service and customer experience.
        </p>
        <p className="pt-2 text-sm font-poppins text-gray-1 sm:text-base">
          In the bustling world of hospitality, the right talent can make all
          the difference. A skilled bartender does more than just mix drinks;
          they create an atmosphere, engage with guests, and represent your
          brand. Here's why hiring a qualified bartender is essential:
        </p>
        <div className="flex flex-col gap-4 pt-4">
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-4">
            <img
              src="/images/Customer_Icon.svg"
              alt="Icon"
              className="w-auto h-12 sm:h-16"
            />
            <p className="text-sm font-normal font-poppins text-gray-1 sm:text-base">
              <span className="font-bold text-black font-poppins">
                Customer Experience:
              </span>{" "}
              The bartender is often the first point of contact for your guests.
              A friendly, knowledgeable bartender can turn a good night into a
              great one by providing exceptional service and memorable
              interactions.
            </p>
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-4">
            <img
              src="/images/Customer_Icon.svg"
              alt="Icon"
              className="w-auto h-12 sm:h-16"
            />
            <p className="text-sm font-normal font-poppins text-gray-1 sm:text-base">
              <span className="font-bold text-black font-poppins">
                Customer Experience:
              </span>{" "}
              The bartender is often the first point of contact for your guests.
              A friendly, knowledgeable bartender can turn a good night into a
              great one by providing exceptional service and memorable
              interactions.
            </p>
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-4">
            <img
              src="/images/Customer_Icon.svg"
              alt="Icon"
              className="w-auto h-12 sm:h-16"
            />
            <p className="text-sm font-normal font-poppins text-gray-1 sm:text-base">
              <span className="font-bold text-black font-poppins">
                Customer Experience:
              </span>{" "}
              The bartender is often the first point of contact for your guests.
              A friendly, knowledgeable bartender can turn a good night into a
              great one by providing exceptional service and memorable
              interactions.
            </p>
          </div>
        </div>
      </div>
      <img
        src="/images/Bear_Glass.svg"
        alt="Bear_Glass"
        className="absolute bottom-0 right-0 w-auto h-[15rem] lg:h-[25rem] max-lg:hidden"
      />
    </div>
  );
}

export default SEOLadingSection2;
