import React from "react";
import PageHeaderNav from "../../pages/PageHeaderNav";

function SEOLandingBody() {
  return (
    <div className="relative md:min-h-screen">
      <div className="bg-bg-Header bg-cover bg-no-repeat bg-center overflow-hidden rounded-br-[40%] rounded-bl-[80%] px-4 sm:px-6 lg:px-8 pb-32 sm:pb-40 lg:pb-48">
        <PageHeaderNav />
        <div className="w-full pt-16 text-center sm:pt-24 lg:pt-32">
          <h1 className="font-normal text-white font-Righteous text-4xl sm:text-5xl lg:text-7xl leading-tight sm:leading-[80px]">
            Hire a Bartender in
          </h1>
          <h1 className="font-normal text-yellow-1 font-Righteous text-4xl sm:text-5xl lg:text-7xl leading-tight sm:leading-[80px]">
            Charleston
          </h1>
          <div className="w-full px-4 pt-4 space-y-2 sm:px-6 lg:px-8">
            <p className="text-lg text-white sm:text-xl lg:text-2xl text-wrap font-poppins">
              Finding the right bartenders and hospitality staff can
              significantly
            </p>
            <p className="text-lg text-white sm:text-xl lg:text-2xl text-wrap font-poppins">
              impact the success of your bar, restaurant, or event venue.
            </p>
            <p className="text-lg text-white sm:text-xl lg:text-2xl text-wrap font-poppins">
              At Barooom, we understand this need deeply.
            </p>
          </div>
        </div>
      </div>
      <img
        src="/images/Glass_Images.svg"
        alt="Glass_Img"
        className="absolute bottom-0 w-64 sm:w-96 lg:w-[35rem] h-fit max-sm:hidden"
      />
    </div>
  );
}

export default SEOLandingBody;
