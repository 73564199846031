import React, { useEffect } from "react";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import NewFooter from "../components/landing/NewFooter";
import { Helmet } from "react-helmet-async";
export default function AboutUs() {
  // useEffect(() => {
  //   // Set custom page title
  //   document.title = "My Custom Title - Subtitle Here";

  //   // Optional: Reset to default title when component unmounts
  //   return () => {
  //     document.title = "Default Title";
  //   };
  // }, []);

  // add blog in sitemap

  return (
    <>
      <Helmet>
        <title>
          Barooom: Trusted Local Bartender Staffing & Community Network
        </title>
        <meta
          name="description"
          content="Discover Barooom: a community-driven bartender staffing app connecting bars with trusted local bartenders. Experience hassle-free booking, verified profiles, and reliable support. Join our network today!"
        />
        <meta
          name="keywords"
          content="Pub jobs near me, Bar Staff Job near me, bar staff responsibilities, Bartenders temporary jobs, bartenders needed near me, bartender staffing, cocktail makers, hiring bartenders, barmaid jobs, barmen job, hiring for bartenders, Barooom, Bartender Staffing, Bartender Gigs, On-Demand Bartenders, Hire Bartenders, Bartender Jobs, Barooom, Local Bartenders, Community Bartending App, Trusted Bartender Staffing, On-Demand Local Bartenders, Bar Staffing Network, Beginner Bartending, Bartender Career, Distilled Spirits, Wedding Bartending, Bartender Licensing, Bartending Jobs Charleston, Bar Staff Jobs Charleston, Charleston Mixologist Jobs, Charleston Server Jobs, Bar Jobs Charleston SC, Hire Bartenders Charleston, Charleston Bar Staffing, Charleston Event Bartenders, Restaurant Staffing Charleston, Hire Mixologists Charleston, Contact Barooom, Barooom Support, Barooom Inquiries, Bartender Staffing Contact, Barooom Customer Service"
        />
        <link rel="canonical" href="https://barooom.com/about-us" />
      </Helmet>
      <ContentWrapper
        type={"AboutSection"}
        title={["About", "US"]}
        content={"<h1>Just and HTML</h1>"}
      />

      <NewFooter />
    </>
  );
}
