import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthenticatedWithoutSetupLayout = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const [allowView, setAllowView] = useState(false);

  useEffect(() => {
    if (!user || user === null) {
      navigate("/");
    } else {
      if (!user.profileSetup) {
        setAllowView(true);
      } else if (user.account_status == 5) {
        navigate("/verification-pending");
      } else {
        navigate("/dashboard");
      }
    }
  }, [user]);

  return <>{allowView && children}</>;
};

export default AuthenticatedWithoutSetupLayout;
