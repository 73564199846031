import React from "react";
import PageHeaderNav from "../../pages/PageHeaderNav";

function EstabLanding01() {
  return (
    <div className="h-[100vh] relative">
      <div className="h-full bg-center bg-no-repeat bg-cover bg-bg-Header-1">
        <PageHeaderNav />
        <div className="w-full pt-24 text-center">
          <h1 className="font-normal text-white font-Righteous text-6xl leading-[80px]">
            Bartending Jobs in{" "}
          </h1>
          <h1 className="font-normal text-yellow-1 font-Righteous text-6xl leading-[80px]">
            Charleston
          </h1>
          <div className="w-full pt-4">
            <p className="text-xl text-white text-wrap font-poppins">
              Are you a bartender, mixologist, server, or other bar staff member
              looking for{" "}
            </p>
            <p className="text-xl text-white text-wrap font-poppins">
              exciting job opportunities in Charleston? Barooom is here to help
              you find the{" "}
            </p>
            <p className="text-xl text-white text-wrap font-poppins">
              perfect gig that matches your skills, passion, and availability.
            </p>
          </div>
        </div>
      </div>
      <img
        src="/images/Bottom_Smoke.svg"
        alt="Bottom_Smoke"
        className="absolute bottom-0 w-full md:translate-y-10 "
      />
    </div>
  );
}

export default EstabLanding01;
