const DEP_TYPE = process.env.REACT_APP_DEPLOYMENT || "local";

const SERVER_HOSTNAME =
  (DEP_TYPE === "production"
    ? process.env.REACT_APP_SERVER_HOSTNAME_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_SERVER_HOSTNAME_DEV
    : process.env.REACT_APP_SERVER_HOSTNAME_LOCAL) || "";

const FRONT_HOSTNAME =
  (DEP_TYPE === "production"
    ? process.env.REACT_APP_FRONT_HOSTNAME_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_FRONT_HOSTNAME_DEV
    : process.env.REACT_APP_FRONT_HOSTNAME_LOCAL) || "";

const STRIPE_PUBLIC_KEY =
  (DEP_TYPE === "production"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_LOCAL) || "";

const STRIPE_SECRET_KEY =
  (DEP_TYPE === "production"
    ? process.env.REACT_APP_STRIPE_SECRET_KEY_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_STRIPE_SECRET_KEY_DEV
    : process.env.REACT_APP_STRIPE_SECRET_KEY_LOCAL) || "";

const CDN_LINK =
  DEP_TYPE === "production"
    ? process.env.REACT_APP_CDN_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_CDN_DEV
    : "";

const ONE_SIGNAL_APP_ID =
  DEP_TYPE === "production"
    ? process.env.REACT_APP_ONE_SIGNAL_APP_ID_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_ONE_SIGNAL_APP_ID_DEV
    : process.env.REACT_APP_ONE_SIGNAL_APP_ID_LOCAL || "";

const ONE_SIGNAL_REST_API_KEY =
  DEP_TYPE === "production"
    ? process.env.REACT_APP_ONE_SIGNAL_REST_API_KEY_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_ONE_SIGNAL_REST_API_KEY_DEV
    : process.env.REACT_APP_ONE_SIGNAL_REST_API_KEY_LOCAL || "";

const ONE_SIGNAL_SAFARI_WEB_ID =
  DEP_TYPE === "production"
    ? process.env.REACT_APP_SAFARI_WEB_ID_PROD
    : DEP_TYPE === "development"
    ? process.env.REACT_APP_SAFARI_WEB_ID_DEV
    : process.env.REACT_APP_SAFARI_WEB_ID_LOCAL || "";

const PLAY_STORE_LINK = process.env.REACT_APP_PLAY_STORE_LINK;

const APP_STORE_LINK = process.env.REACT_APP_APP_STORE_LINK;
const ONE_Signal = {
  appId: ONE_SIGNAL_APP_ID,
  restApiKey: ONE_SIGNAL_REST_API_KEY,
  safari_web_id: ONE_SIGNAL_SAFARI_WEB_ID,
};
const configuration = {
  mode: DEP_TYPE,
  cdnFront: CDN_LINK,
  apiUrl: SERVER_HOSTNAME,
  appleClientId: "com.barooom.userapp.client",
  appleRedirectUri: FRONT_HOSTNAME,
  googleClientId:
    "1020391111902-fc2ja6c48alh780b0l17trco2dt1984c.apps.googleusercontent.com",
  stripeKey: STRIPE_SECRET_KEY,
  stripePublicKey: STRIPE_PUBLIC_KEY,
  vapidKey:
    "BCjoKlhc791En9xNRcAP9GJC6AMhnGHmCiKcYZ-1TYrz7BTi6YNnsncHq9dPNJjQV3AvUjBHfRlkm4GJ69JZWnE",
  googlePlayStoreLink: PLAY_STORE_LINK,
  appleAppStoreLink: APP_STORE_LINK,
  oneSignal: ONE_Signal,
};

export default configuration;
