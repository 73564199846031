import { useState } from "react";
import style from "../../dashboard.module.css";
import { useEffect } from "react";
import CurrentG from "./components/currentG";
import NewOpportunities from "./components/newOpportunities";
import PastGig from "./components/pastGigs";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../config/configuration";

import {
  ApplyForGig,
  fetchBartAvailability,
  fetchBartenderDetailsApi,
  getAppliedGigsforBart,
  getCurrentGigsforBart,
  getNewOpportunityforBart,
  getPastGigsforBart,
  toggleBartAvailability,
} from "../../../../axios/axios";

import { Button, Switch } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import RightSideTile from "./components/RightSideTile";
import AppliedGigs from "./components/AppliedGigs";
import DashHeader from "../../DashHeader";
import InternalBar from "../../../internalBar/InternalBar";
import { toast } from "react-toastify";
import { formatNumber } from "../../../../utils/helper";
import { useSwipeable } from "react-swipeable";
import StripeSetupModal from "../../../Modals/StripeSetupModal";
import { useNavigate } from "react-router-dom";

dayjs.extend(customParseFormat);

function BartenderDashboard({ activevalue }) {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showfilter, setshowfilter] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [change, setChange] = useState(false);
  const [Newchange, setNewChange] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [details, setDetails] = useState({});
  const [myTimeOut, setMytimeOut] = useState();
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    cg: {
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      datas: [],
    },
    ag: {
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      datas: [],
    },
    no: {
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      datas: [],
    },
    pg: {
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      filter: "",
      filter1: "",
      datas: [],
    },
  });

  const [newOpp, setNewOpp] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  // Define swipe handlers
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setTab((prevTab) => (prevTab < 3 ? prevTab + 1 : prevTab)),
    onSwipedRight: () =>
      setTab((prevTab) => (prevTab >= 0 ? prevTab - 1 : prevTab)),
    trackMouse: true, // Allows swiping with mouse as well
  });

  const fetchUserDetails = () => {
    fetchBartenderDetailsApi(user?.token)
      .then((result) => {
        setDetails({ ...result.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchConditionalGigs = () => {
    if (tab === 0) {
      getCurrentGigsforBart({
        page: data.cg.page,
        search: data.cg.search,
        token: user?.token,
      })
        .then((result) => {
          let res = result.data.data;
          let x = { ...data };

          x.cg = {
            ...x.cg,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page,
            datas: [...res.history],
          };

          setData(x);
        })
        .catch((err) => {
          console.log("cg", err);
        });
    } else if (tab === 1) {
      getAppliedGigsforBart({
        page: data.no.page,
        search: data.no.search,
        token: user?.token,
      })
        .then((result) => {
          let res = result.data.data;
          let x = { ...data };

          x.ag = {
            ...x.ag,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page,
            datas: [...res.history],
          };

          setData(x);
        })
        .catch((err) => {
          console.log("ag", err);
        });
    } else if (tab === 2) {
      getNewOpportunityforBart({
        page: data.no.page,
        search: data.no.search,
        token: user?.token,
      })
        .then((result) => {
          let res = result.data.data;
          let x = { ...data };

          x.no = {
            ...x.no,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page,
            datas: [...res.history],
          };

          setData(x);
          let his = res.history.slice(0, 3);

          setNewOpp({ ...res, history: his });
        })
        .catch((err) => {
          console.log("no", err);
        });
    } else if (tab === 3) {
      getPastGigsforBart({
        page: data.pg.page,
        search: data.pg.search,
        filter: data.pg.filter,
        filter1: data.pg.filter1,
        token: user?.token,
      })
        .then((result) => {
          let res = result.data.data;
          let x = { ...data };

          x.pg = {
            ...x.pg,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page,
            datas: [...res.history],
          };

          setData(x);
        })
        .catch((err) => {
          console.log("pg", err);
        });
    }
  };

  const handleApplyGig = (job_id) => {
    // if (user.connected_acc_setup != "2") {
    //   setShowModal2(!showModal2);
    //   // toast.warning("Account setup for Accepting payment is required .");
    //   return;
    // }

    ApplyForGig({ token: user?.token, job_id })
      .then((result) => {
        toast.success(result?.data.message);
        setNewChange(!Newchange);
        setChange(!change);
        // data = data.filter((item) => item._id !== job_id);
      })
      .catch((err) => {
        if (err.type == 2) {
          if (err.message != "") {
            //set the message sent from the backend
            setMessage(err.message);
          } else {
            setMessage(
              `You need to provide bank details and personal information for accepting payments, before you can start applying for the gigs.`
            );
          }
          setShowModal2(!showModal2);
        } else if (err.type === 5) {
          if (err.message !== "") {
            //set the message sent from the backend
            toast.warning("User Profile Not Setup");
          } else {
            setMessage(
              `You need to provide bank details and personal information for accepting payments, before you can start applying for the gigs.`
            );
          }
          navigate("/profile?setup=true");
        } else {
          toast.error(err?.message | "Internal Server Error!");
        }
      });
  };

  const fetchNewOpportunity = () => {
    getNewOpportunityforBart({
      page: data.no.page,
      search: data.no.search,
      token: user?.token,
    })
      .then((result) => {
        let res = result.data.data;
        let his = res.history.slice(0, 3);

        setNewOpp({ ...res, history: his });
      })
      .catch((err) => {
        console.log("new Opp", err);
      });
  };

  useEffect(() => {
    if (activevalue) setTab(activevalue);
    else setTab(0);
  }, []);

  useEffect(() => {
    fetchUserDetails();
    fetchNewOpportunity();
  }, []);

  useEffect(() => {
    fetchConditionalGigs();
  }, [change]);

  useEffect(() => {
    fetchNewOpportunity();
  }, [Newchange]);

  useEffect(() => {
    fetchConditionalGigs();
  }, [tab, data.pg.filter1, data.pg.filter]);

  useEffect(() => {
    clearTimeout(myTimeOut);

    setMytimeOut(
      setTimeout(() => {
        fetchConditionalGigs();
      }, [700])
    );
  }, [data.cg.search, data.no.search, data.pg.search]);

  useEffect(() => {
    fetchConditionalGigs();
  }, [data.cg.page, data.no.page, data.pg.page]);

  const getSearchValue = () => {
    if (tab === 0) {
      return data.cg.search;
    } else if (tab === 1) {
      return data.ag.search;
    } else if (tab === 2) {
      return data.no.search;
    } else if (tab === 3) {
      return data.pg.search;
    }
  };

  const searchHandler = (val) => {
    let x = { ...data };

    if (tab === 0) {
      x.cg = { ...x.cg, search: val };
    } else if (tab === 1) {
      x.ag = { ...x.ag, search: val };
    } else if (tab === 2) {
      x.no = { ...x.no, search: val };
    } else if (tab === 3) {
      x.pg = { ...x.pg, search: val };
    }

    setData(x);
  };

  const getPreviousValue = () => {
    if (tab === 0) {
      return data.cg.prev;
    } else if (tab === 1) {
      return data.ag.prev;
    } else if (tab === 2) {
      return data.no.prev;
    } else if (tab === 3) {
      return data.pg.prev;
    }
  };

  const getNextValue = () => {
    if (tab === 0) {
      return data.cg.next;
    } else if (tab === 1) {
      return data.ag.next;
    } else if (tab === 2) {
      return data.no.next;
    } else if (tab === 3) {
      return data.pg.next;
    }
  };

  const getTotalPage = () => {
    if (tab === 0) {
      return data.cg.total_page;
    } else if (tab === 1) {
      return data.ag.total_page;
    } else if (tab === 2) {
      return data.no.total_page;
    } else if (tab === 3) {
      return data.pg.total_page;
    }
  };

  const setPage = (val) => {
    let x = { ...data };

    if (tab === 0) {
      x.cg = { ...x.cg, page: val };
    } else if (tab === 1) {
      x.no = { ...x.ag, page: val };
    } else if (tab === 2) {
      x.no = { ...x.no, page: val };
    } else if (tab === 3) {
      x.pg = { ...x.pg, page: val };
    }

    setData(x);
  };

  const increasePage = () => {
    let x = { ...data };

    if (tab === 0) {
      x.cg = { ...x.cg, page: x.cg.page + 1 };
    } else if (tab === 1) {
      x.ag = { ...x.ag, page: x.no.page + 1 };
    } else if (tab === 2) {
      x.no = { ...x.no, page: x.no.page + 1 };
    } else if (tab === 3) {
      x.pg = { ...x.pg, page: x.pg.page + 1 };
    }

    setData(x);
  };

  const decreasePage = () => {
    let x = { ...data };

    if (tab === 0) {
      x.cg = { ...x.cg, page: x.cg.page - 1 };
    } else if (tab === 1) {
      x.ag = { ...x.ag, page: x.no.page - 1 };
    } else if (tab === 2) {
      x.no = { ...x.no, page: x.no.page - 1 };
    } else if (tab === 3) {
      x.pg = { ...x.pg, page: x.pg.page - 1 };
    }

    setData(x);
  };

  const getCurrentPage = () => {
    if (tab === 0) {
      return data.cg.page;
    } else if (tab === 1) {
      return data.ag.page;
    } else if (tab === 1) {
      return data.no.page;
    } else if (tab === 2) {
      return data.pg.page;
    }
  };

  const toggleAvailablity = () => {
    toggleBartAvailability({
      available: !isActive,
      token: user?.token,
    })
      .then((result) => {
        setIsActive(result.data.data);
      })
      .catch((err) => {
        if (err.type === 2) {
          if (err.message !== "") {
            //set the message sent from the backend
            setMessage(err.message);
          } else {
            setMessage(
              `You need to provide bank details and personal information for accepting payments before making your self available.`
            );
          }
          setShowModal2(!showModal2);
        } else if (err.type === 5) {
          if (err.message !== "") {
            setMessage(err.message);
            //set the message sent from the backend
            toast.warning("User Profile Not Setup");
          } else {
            setMessage(
              "You need to provide bank details and personal information for accepting payments, before making your self available."
            );
          }
          navigate("/profile?setup=true");
        } else {
          toast.error(err?.message | "Toggle Availability error");
        }
      });
  };

  useEffect(() => {
    if (showModal2 == false) setMessage("");
  }, [showModal2]);

  const GettoggleAvailablity = () => {
    fetchBartAvailability({
      token: user?.token,
    })
      .then((result) => {
        setIsActive(result.data.data);
      })
      .catch((err) => {
        console.log("Toggle Availability error", err);
      });
  };

  useEffect(() => {
    GettoggleAvailablity();
  }, []);

  const NavItems = [
    { index: 0, label: "Current Gigs" },
    { index: 1, label: "Applied Gigs" },
    { index: 2, label: "New Opportunities" },
    { index: 2, label: "Past Gigs" },
  ];

  const handelTabClick = (ind) => {
    setTab(ind);
  };

  return (
    <div {...swipeHandlers}>
      <DashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Welcome</span>{" "}
              <span className="text-yellow-400 ">{user?.name}</span>
            </div>
            {/* <img
              loading="lazy"
              src="/images/completeProfile/hand.png"
              className="shrink-1 aspect-square mt-5 h-[43px] w-[40px]"
            /> */}
          </>
        }
      />

      <div className="relative min-h-[85%] justify-between w-full gap-6 px-2 pt-6 lg:flex">
        <div className="lg:w-[70%]">
          <div
            className="flex items-center justify-center shadow-2xl rounded-3xl h-28 "
            style={{
              backgroundImage: `url('/images/Bar_Background.svg')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="flex items-center justify-center w-1/2 gap-3 pl-4 lg:w-1/3">
              <img
                loading="lazy"
                src={
                  user?.profileImg || user?.pic
                    ? `${config.cdnFront}/${user?.profileImg || user?.pic}`
                    : "/images/Dummy_User.jpg"
                }
                className="h-[4rem] w-[4rem] aspect-square"
                style={{ borderRadius: "50%" }}
              />
              <div className="flex flex-col flex-1 my-5">
                <div className="text-[1.3rem] font-poppins font-medium text-black">
                  {user?.name}
                </div>
                <div className="text-[1rem] text-neutral-500 flex-wrap font-poppins font-normal">
                  {details?.designation?.map((elem) => `${elem} `)}
                </div>
              </div>
            </div>
            <div className="border border-[#B6B6B6] h-24" />
            <div className="flex items-center justify-center w-full gap-10">
              <div className="flex flex-col items-center justify-center">
                <p className="font-normal font-Righteous text-establishment-red text-[2.25rem]">
                  {" "}
                  {details?.UserData?.totalJobs || 0}
                </p>
                <p className="text-lg font-normal font-poppins">No. of Gigs</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="font-normal font-Righteous text-establishment-red text-[2.25rem]">
                  {" "}
                  $
                  {details?.UserData?.totalEarnings
                    ? formatNumber(details?.UserData?.totalEarnings)
                    : 0}
                </p>
                <p className="text-lg font-normal font-poppins">Earnings</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="font-normal font-Righteous text-establishment-red text-[2.25rem]">
                  {" "}
                  {details?.UserData?.totalreview || 0}
                </p>
                <p className="text-lg font-normal font-poppins">Reviews</p>
              </div>
            </div>
          </div>
          <div className="relative self-center px-4 pb-6 mt-5 bg-white shadow-2xl rounded-3xl pt-7">
            <InternalBar
              NavItems={NavItems}
              onClick={handelTabClick}
              tab={tab}
            />
            <div>
              <div className="flex justify-between gap-5 mt-5 max-lg:flex-wrap max-md:max-w-full">
                <div className="flex-auto my-auto ml-2 text-[18px] text-black">
                  {tab === 0 && "Current Gigs"}
                  {tab === 1 && "Applied Gigs"}
                  {tab === 2 && "New Opportunities"}
                  {tab === 3 && "Past Gigs"}
                </div>
                <div className="flex gap-5 justify-between px-5 py-4 mr-1 text-sm h-[50px] w-[280px] whitespace-nowrap bg-white border border-gray-200 border-solid shadow-sm rounded-[100px] text-neutral-400">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      style={{ width: "100%" }}
                      className="border-none outline-none"
                      type="text"
                      placeholder="Search"
                      value={getSearchValue()}
                      onChange={(e) => searchHandler(e.target.value)}
                    ></input>
                  </div>
                  <img
                    loading="lazy"
                    src="/images/completeProfile/search.png"
                    alt=""
                    className="shrink-0 aspect-[1.05] w-[20px]"
                  />
                </div>
                {tab == 3 && (
                  <img
                    loading="lazy"
                    src="/images/completeProfile/filter.png"
                    className="shrink-0 aspect-square w-[50px] h-auto cursor-pointer"
                    alt=""
                    onClick={() => setshowfilter(!showfilter)}
                  />
                )}
                {tab == 3 && showfilter && (
                  <>
                    <div className="absolute z-50 flex overflow-x-hidden overflow-y-auto translate-y-12 outline-none right-5 w-fit h-fit focus:outline-none">
                      <div className="flex flex-col px-4 py-6 text-base text-black rounded-3xl border border-solid bg-white border-zinc-400 max-w-[375px]">
                        <div className="flex flex-col w-full py-4 pl-3 pr-10 bg-white rounded-xl">
                          <div
                            onClick={() => setshowfilter(false)}
                            className="absolute text-2xl cursor-pointer top-1 right-4"
                          >
                            &#10005;
                          </div>
                          <div className="flex flex-col gap-2 w-60">
                            <p className="text-lg font-medium text-red-500">
                              Earnings
                            </p>
                            <div className="flex">
                              <div className="flex items-center justify-center w-1/5">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={data.pg.filter === "processed"}
                                  onChange={() =>
                                    setData((prevState) => ({
                                      ...prevState,
                                      pg: {
                                        ...prevState.pg,
                                        filter:
                                          prevState.pg.filter === "processed"
                                            ? ""
                                            : "processed",
                                      },
                                    }))
                                  }
                                />
                              </div>
                              <p className="w-full">Earnings Processed</p>
                            </div>
                            <div className="flex mt-1">
                              <div className="flex items-center justify-center w-1/5">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={data.pg.filter === "initialized"}
                                  onChange={() =>
                                    setData((prevState) => ({
                                      ...prevState,
                                      pg: {
                                        ...prevState.pg,
                                        filter:
                                          prevState.pg.filter === "initialized"
                                            ? ""
                                            : "initialized",
                                      },
                                    }))
                                  }
                                />
                              </div>
                              <p className="w-full">Earnings Not Processed</p>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2 mt-2 w-60">
                            <p className="text-lg font-medium text-red-500">
                              Reviews
                            </p>
                            <div className="flex">
                              <div className="flex items-center justify-center w-1/5">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={data.pg.filter1 === true}
                                  onChange={() =>
                                    setData((prevState) => ({
                                      ...prevState,
                                      pg: {
                                        ...prevState.pg,
                                        filter1:
                                          prevState.pg.filter1 === true
                                            ? ""
                                            : !prevState.pg.filter1,
                                      },
                                    }))
                                  }
                                />
                              </div>
                              <p className="w-full">Rated Bartender</p>
                            </div>
                            <div className="flex mt-1">
                              <div className="flex items-center justify-center w-1/5">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={data.pg.filter1 === false}
                                  onChange={() =>
                                    setData((prevState) => ({
                                      ...prevState,
                                      pg: {
                                        ...prevState.pg,
                                        filter1:
                                          prevState.pg.filter1 === false
                                            ? ""
                                            : false,
                                      },
                                    }))
                                  }
                                />
                              </div>
                              <p className="w-full">Not Rated Bartender</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {tab === 0 && <CurrentG data={data.cg.datas} dayjs={dayjs} />}
              {tab === 1 && (
                <AppliedGigs
                  data={data.ag.datas}
                  dayjs={dayjs}
                  setChange={setChange}
                  change={change}
                />
              )}
              {tab === 2 && (
                <NewOpportunities
                  data={data.no.datas}
                  handleApplyGig={handleApplyGig}
                />
              )}
              {tab === 3 && <PastGig data={data.pg.datas} dayjs={dayjs} />}

              {getTotalPage() > 0 && (
                <div
                  style={{
                    width: "auto",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    margin: "25px 0 0 0",
                    padding: "0 20px",
                  }}
                >
                  <Button
                    type="text"
                    disabled={!getPreviousValue()}
                    onClick={decreasePage}
                  >
                    Previous
                  </Button>

                  {Array.from({ length: getTotalPage() }).map((elem, ind) => (
                    <Button
                      key={`Pagi${ind}${tab}`}
                      style={{
                        height: "20px",
                        width: "20px",
                        padding: "0",
                        fontSize: "12px",
                        margin: "0 3px",
                        background:
                          getCurrentPage() === ind + 1
                            ? "rgb(255, 51, 51)"
                            : "white",
                        color: getCurrentPage() === ind + 1 ? "white" : "black",
                      }}
                      onClick={() => setPage(ind + 1)}
                    >
                      {ind + 1}
                    </Button>
                  ))}

                  <Button
                    type="text"
                    disabled={!getNextValue()}
                    onClick={increasePage}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* .. Right Side start.. */}
        <div className="mt-6 lg:w-[30%] lg:mt-0">
          <div className="bg-white shadow-2xl rounded-3xl">
            <div className="p-4 ">
              <div className="flex gap-5 justify-between pt-2 pr-3.5 max-xl:flex-wrap max-md:max-w-full">
                <div
                  className={`flex-auto text-[18px] text-black} ${style.header}`}
                >
                  Available Now
                </div>

                <Switch
                  checked={isActive}
                  onChange={() => toggleAvailablity()}
                  style={{ backgroundColor: isActive ? "red" : "gray" }}
                  defaultChecked
                />
              </div>
              <div className="mt-4 text-sm tracking-tighter text-neutral-400 max-xl:flex-wrap max-md:max-w-full">
                Establishments will see your expected hourly rate, but their
                posting may offer a higher/lower offer for a gig, you choose
                whether to accept or not at time of posting.
              </div>
              <div className="mt-8 ">
                {newOpp.history.map((elem, ind) => {
                  return (
                    <RightSideTile
                      key={`righttile${ind}`}
                      dayjs={dayjs}
                      elem={elem}
                      handleApplyGig={handleApplyGig}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal1 && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white outline-none rounded-xl focus:outline-none">
                {/*header*/}
                <button
                  className="self-end pt-3 pr-4 text-2xl"
                  onClick={() => setShowModal1(false)}
                >
                  {" "}
                  &#10005;
                </button>
                <img
                  src="/images/Success1.png"
                  className="w-[400px] px-20 pt-10 pb-20"
                />
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}
      {showModal2 && (
        <StripeSetupModal
          ModalShow={showModal2}
          setModalShow={setShowModal2}
          message={message}
        />
      )}
      {/* </BartenderWrapperDash> */}
    </div>
  );
}
export default BartenderDashboard;
