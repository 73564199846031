import React from "react";

function SEOLandingSection6() {
  return (
    <div className="min-h-[100vh] pb-10">
      <div className="h-[100vh]">
        <div className="relative flex flex-col items-center justify-center">
          <video
            className="absolute top-0 left-0 object-cover w-full h-full"
            autoPlay
            loop
            muted
          >
            <source src="/images/SEO-Landing_PlayBack.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="relative z-10 flex flex-col items-center justify-center px-4 pt-8 text-white">
            <h1 className="text-2xl font-normal text-center sm:text-3xl lg:text-4xl font-Righteous">
              Building{" "}
              <span className="font-Righteous text-error">Relationships</span>{" "}
              with Bartenders
            </h1>
            <p className="pt-3 font-poppins w-full lg:max-w-[45%] text-center text-sm sm:text-base">
              At Barooom, we believe in fostering long-term relationships
              between establishments and bartenders. Our platform not only helps
              you find immediate staffing solutions but also enables you to
              build a network of reliable professionals you can call upon in the
              future.
            </p>
          </div>
          <div className="relative z-10 flex flex-col lg:flex-row justify-around w-full gap-8 px-4 mt-12 lg:translate-y-[50%]">
            <div className="relative bg-no-repeat bg-cover bg-mixolog w-full lg:w-[35rem] min-h-[18rem] rounded-xl shadow-xl">
              <div className="absolute inset-0 bg-[#F7B81F] bg-opacity-95 rounded-xl"></div>
              <div className="relative z-10 flex flex-col items-center justify-center h-full p-6 text-white">
                <h2 className="text-2xl font-normal text-center sm:text-3xl font-Righteous">
                  Create a Talent Pool
                </h2>
                <p className="pt-3 text-sm text-center text-white sm:text-base font-poppins">
                  As you work with various bartenders, you can create a talent
                  pool of your favorites. This allows you to quickly reach out
                  to individuals who have already proven their skills and fit
                  well with your establishment's culture.
                </p>
              </div>
            </div>
            <div className="bg-white w-full lg:w-[35rem] min-h-[18rem] relative rounded-xl shadow-xl">
              <div className="flex flex-col items-center justify-center h-full p-6">
                <h2 className="text-2xl font-normal text-center text-black sm:text-3xl font-Righteous">
                  Ongoing Communication
                </h2>
                <p className="pt-3 text-sm text-center text-black sm:text-base font-poppins">
                  We encourage open communication between you and the bartenders
                  you hire. Discuss expectations, provide feedback, and maintain
                  a dialogue to ensure a successful working relationship that
                  benefits both parties.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col items-center justify-center px-4 mt-24 lg:flex-row sm:px-8 lg:px-40 lg:mt-0">
        <img
          src="/images/Side_SEO_Landing_01.svg"
          alt="Bartender_Image"
          className="w-auto h-auto lg:h-[35rem] max-w-full mb-8 lg:mb-0"
        />
        <div className="lg:ml-8">
          <h1 className="text-2xl font-normal text-center sm:text-3xl lg:text-4xl lg:text-left font-Righteous">
            The <span className="font-Righteous text-error">Barooom</span>{" "}
            Experience
          </h1>
          <p className="pt-3 text-sm text-center font-poppins text-gray-1 lg:text-left sm:text-base">
            When you hire through Barooom, you can expect a seamless and
            enjoyable experience:
          </p>

          <div className="flex flex-col gap-6 pt-6">
            <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-start">
              <img
                src="/images/Customer_Icon.svg"
                alt="Icon"
                className="w-auto h-12 sm:h-16"
              />
              <p className="text-sm font-normal text-center font-poppins text-gray-1 sm:text-left sm:text-base">
                <span className="font-bold text-black font-poppins">
                  Professionalism:
                </span>{" "}
                Our bartenders arrive on time, dressed appropriately, and ready
                to provide exceptional service. They understand the importance
                of professionalism and are committed to representing your brand
                positively.
              </p>
            </div>
            <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-start">
              <img
                src="/images/Customer_Icon.svg"
                alt="Icon"
                className="w-auto h-12 sm:h-16"
              />
              <p className="text-sm font-normal text-center font-poppins text-gray-1 sm:text-left sm:text-base">
                <span className="font-bold text-black font-poppins">
                  Attention to Detail:
                </span>{" "}
                Bartenders hired through Barooom are trained to pay attention to
                every detail, from drink presentation to guest interactions.
                Their goal is to ensure that every aspect of your beverage
                service exceeds expectations.
              </p>
            </div>
            <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-start">
              <img
                src="/images/Customer_Icon.svg"
                alt="Icon"
                className="w-auto h-12 sm:h-16"
              />
              <p className="text-sm font-normal text-center font-poppins text-gray-1 sm:text-left sm:text-base">
                <span className="font-bold text-black font-poppins">
                  Problem-Solving Skills:
                </span>{" "}
                Events can be unpredictable, but our bartenders are equipped to
                handle any challenges that arise. Whether it's managing
                unexpected crowd sizes or adjusting drink orders, they know how
                to adapt and maintain high-quality service.
              </p>
            </div>
          </div>
        </div>
        <img
          src="/images/Side_Graphic_01.svg"
          alt="Bear_Glass"
          className="hidden lg:block absolute mt-auto right-0 h-[25rem]"
        />
      </div>
    </div>
  );
}

export default SEOLandingSection6;
