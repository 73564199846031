import React, { useEffect, useState } from "react";
import { FetchBlog } from "../axios/axios";
import "./Blogs.css";
import style from "../components/ContentWrapper/ContentWrapper.module.css";
import { Link, useLocation, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Helmet } from "react-helmet-async";
import NewFooter from "../components/landing/NewFooter";
import dayjs from "dayjs";

const BlogDetails = () => {
  const searchId = useLocation().search;
  const bid = searchId.split("=")[1];

  const [data, setdata] = useState({});
  const getBlogs = () => {
    if (bid) {
      FetchBlog({ bid: bid })
        .then((result) => {
          setdata(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      <div
        style={{
          background: `url(/AboutBarrom/backgroundImg.png)`,
          backgroundSize: "cover",
        }}
      >
        <header className={style.header}>
          <Helmet>
            <title>{data?.metaTags?.title}</title>
            <meta name="description" content={data?.metaTags?.description} />
          </Helmet>
        </header>
        <Link to={"/"}>
          <img
            loading="lazy"
            className="ml-16 size-32"
            src={"/LandingPageImages/Barrom.svg"}
            alt=""
          />
        </Link>
        <div className="ml-16">
          <div className="pb-12">
            <h3 className="text-3xl font-normal leading-5 text-white font-Righteous">
              {data.title}
            </h3>
            <p className="mt-3 text-xl font-light leading-5 text-white font-Righteous">
              <span className="font-bold">Last Updated: </span>
              {dayjs(data?.createdAt).format("MMMM DD,YYYY")}
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }} className="mt-5">
        <p
          onClick={() => window.history.back()}
          style={{ marginLeft: "20px", alignContent: "center" }}
        >
          <ArrowBackIosNewIcon />
        </p>
      </div>
      <div className={style.middlePart}>
        <div
          className={style.contentSection}
          dangerouslySetInnerHTML={{ __html: data?.content }}
        ></div>
      </div>
      <NewFooter />
    </>
  );
};

export default BlogDetails;
