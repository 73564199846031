import React, { useEffect, useState } from "react";
import { FetchBlogsList } from "../axios/axios";
import "./Blogs.css";
import style from "../components/ContentWrapper/ContentWrapper.module.css";
// import style from "../component/ContentWrapper/ContentWrapper.module.css";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Footer from "../components/landing/Footer";
import NewFooter from "../components/landing/NewFooter";
import configuration from "../config/configuration";
const Blogs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const getBlogs = () => {
    FetchBlogsList({ page: 1 })
      .then((result) => {
        let res = result.data;
        let x = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setdata(x);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      <div
        style={{
          background: `url(/AboutBarrom/backgroundImg.png)`,
          backgroundSize: "cover",
        }}
      >
        <Link to={"/"}>
          <img
            loading="lazy"
            className="ml-16 size-32"
            src={"/LandingPageImages/Barrom.svg"}
            alt=""
          />
        </Link>
        <div className="ml-16">
          <div className="pb-12">
            <h3 className="text-4xl font-normal leading-5 text-white font-Righteous">
              Barooom{" "}
              <span className="text-4xl font-normal leading-5 text-yellow-1 font-Righteous">
                Blogs
              </span>
            </h3>
          </div>
        </div>
      </div>
      <div className="blogs-container">
        <div className="mt-10 blogs-header"></div>
        {!loading ? (
          <div className="pt-8 blogs-list">
            {data.history.length > 0 &&
              data.history.map((elem) => (
                <a
                  key={elem._id}
                  className="cursor-pointer blog-item"
                  href={`/blogs/${elem.title}?id=${elem._id}`}
                >
                  <img
                    loading="lazy"
                    className="blog-img"
                    src={`${configuration.cdnFront}/${elem?.blogImg}`}
                    alt={elem.title}
                  />
                  <p className="blog-title">{elem.title}</p>
                </a>
              ))}
          </div>
        ) : (
          <div className="loading-container">
            <CircularProgress />
            <h1>Loading</h1>
          </div>
        )}
      </div>
      <NewFooter />
    </>
  );
};

export default Blogs;

/**
 *     <Helmet>
        <title>
          Barooom Blog: Bartending Tips, Guides, and Industry Insights{" "}
        </title>
        <meta
          name="description"
          content="Explore the Barooom blog for essential bartending tips, career guides, spirit knowledge, cost insights, and licensing information. Stay informed and elevate your bartending skills."
        />
        <meta
          name="keywords"
          content="Beginner Bartending, Bartender Career, Distilled Spirits, Wedding Bartending, Bartender Licensing"
        />
        <link rel="canonical" href="https://barooom.com/blogs" />
      </Helmet>
 */
