import React from "react";

function SEOLandingSection4() {
  const listData = [
    {
      icon: "/images/01_SEO_Section_4.svg",
      title: "Seasonal Staffing Solutions",
      desc: "During peak seasons, such as summer or the holidays, your staffing needs may fluctuate. Barooom allows you to adjust your staff levels to meet demand, ensuring that you always have the right number of hands on deck to provide excellent service.",
    },
    {
      icon: "/images/02_SEO_Section_4.svg",
      title: "Event-Specific Staffing",
      desc: "If you’re hosting a special event, whether it’s a corporate gathering, festival, or private party, Barooom can help you find bartenders who have experience in handling large crowds and can create a fun, engaging atmosphere.",
    },
    {
      icon: "/images/03_SEO_Section_4.svg",
      title: "Efficient Hiring Process",
      desc: "We understand that time is of the essence in the hospitality industry. Our platform allows you to instantly connect with bartenders and staff that meet your specific needs, saving you valuable time.",
    },
    {
      icon: "/images/04_SEO_Section_4.svg",
      title: "Verified Professionals",
      desc: "All staff on our platform undergo a thorough vetting process. We check references, skills, and experience to ensure that you are hiring only the best talent available. This peace of mind allows you to focus on your business while we take care of staffing.",
    },
    {
      icon: "/images/05_SEO_Section_4.svg",
      title: "Time-Saving",
      desc: "Gone are the days of sifting through endless resumes and conducting multiple interviews. With Barooom, you can find quality candidates quickly and easily, allowing you to get back to what you do best—running your establishment.",
    },
  ];
  return (
    <section className="relative flex flex-col items-center w-full min-h-screen px-4 pb-24 sm:px-8">
      <h1 className="text-2xl font-normal text-center sm:text-3xl lg:text-4xl font-Righteous">
        Top-Quality Talent,{" "}
        <span className="font-Righteous text-error"> Just a Click Away</span>
      </h1>
      <p className="pt-3 font-poppins text-gray-1 text-wrap w-full lg:max-w-[70%] text-center text-sm sm:text-base">
        At Barooom, we pride ourselves on providing access to a diverse range of
        hospitality professionals. Here are the key roles you can find through
        our platform:
      </p>
      <button className="border-4 border-[#FF33336B] rounded-[25px] h-fit py-2 sm:py-3 px-4 sm:px-6 bg-[#FF3333] text-white font-poppins font-bold text-center mt-4 text-sm sm:text-base">
        Sign Up for Early Access
      </button>

      <div className="flex flex-col w-full gap-4 mt-8">
        <div className="flex flex-col w-full gap-4 lg:flex-row">
          <img
            src="/images/SEO_Landing_01.svg"
            alt=""
            className="object-cover w-full h-48 lg:w-auto sm:h-64 lg:h-80"
          />
          <div className="bg-[#2D2D2D] rounded-xl lg:rounded-l-xl lg:rounded-br-xl flex flex-col justify-center items-center flex-1 p-4">
            <h2 className="text-2xl font-normal text-white sm:text-3xl font-Righteous">
              Bartender
            </h2>
            <p className="px-2 pt-3 text-sm text-center text-white sm:px-4 sm:text-base font-poppins">
              Our bartenders are skilled in crafting signature cocktails,
              managing bar duties, and delivering exceptional customer service.
              They bring creativity and expertise, ensuring that your bar stands
              out.
            </p>
          </div>
          <div className="relative flex-1 p-4 bg-no-repeat bg-contain bg-mixolog rounded-xl">
            <div className="absolute inset-0 bg-[#F7B81F] bg-opacity-95 rounded-xl lg:rounded-bl-xl"></div>
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
              <h2 className="text-2xl font-normal sm:text-3xl font-Righteous">
                Mixologists
              </h2>
              <p className="px-2 pt-3 text-sm text-center text-white sm:px-4 sm:text-base font-poppins">
                For those looking to offer something unique, our mixologists
                specialize in creating innovative drinks that can elevate your
                menu. They are passionate about mixology and can design custom
                drink menus tailored to your clientele.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-4 lg:flex-row">
          <div className="bg-[#F7B81F] rounded-xl lg:rounded-tr-xl flex flex-col justify-center items-center flex-1 p-4">
            <h2 className="text-2xl font-normal sm:text-3xl font-Righteous">
              Servers and Waiters
            </h2>
            <p className="px-2 pt-3 text-sm text-center text-white sm:px-4 sm:text-base font-poppins">
              In addition to bartenders, we provide polished servers and
              waitstaff who are dedicated to providing top-tier service. These
              professionals are trained to handle food and beverage service with
              grace and efficiency, enhancing the overall dining experience for
              your guests.
            </p>
          </div>
          <div className="relative flex-1 p-4 bg-no-repeat bg-cover bg-seo-landing-back-card rounded-xl">
            <div className="absolute inset-0 bg-[#2D2D2D] bg-opacity-85 rounded-xl lg:rounded-tl-xl lg:rounded-br-xl"></div>
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
              <h2 className="text-2xl font-normal sm:text-3xl font-Righteous">
                Front of the House Support
              </h2>
              <p className="px-2 pt-3 text-sm text-center text-white sm:px-4 sm:text-base font-poppins">
                From hosts to cashiers, our front-of-house staff is ready to
                ensure that your operations run smoothly. These professionals
                are essential in creating a welcoming environment and managing
                guest interactions.
              </p>
            </div>
          </div>
          <div className="bg-[#F7B81F] rounded-xl flex flex-col justify-center items-center flex-1 p-4">
            <h2 className="text-2xl font-normal sm:text-3xl font-Righteous">
              Barbacks
            </h2>
            <p className="px-2 pt-3 text-sm text-center text-white sm:px-4 sm:text-base font-poppins">
              Barbacks play a crucial role in supporting bartenders by keeping
              the bar stocked and organized. They help ensure that everything
              runs seamlessly behind the scenes, allowing bartenders to focus on
              serving customers.
            </p>
          </div>
        </div>

        <div className="w-full h-48 bg-no-repeat bg-cover bg-seo-landing-back-card-01 sm:h-64 lg:h-80 rounded-xl"></div>
      </div>

      <div className="flex flex-col items-center justify-center w-full mt-16 sm:mt-24 lg:mt-32">
        <h1 className="text-2xl font-normal text-center sm:text-3xl lg:text-4xl font-Righteous">
          <span className="font-Righteous text-error">Flexible Staffing </span>
          for All Your Needs
        </h1>
        <p className="pt-3 font-poppins text-gray-1 text-wrap w-full lg:max-w-[50%] text-center text-sm sm:text-base">
          Whether you're hosting a large event, need extra help for a busy
          weekend, or are looking for long-term staffing solutions, Barooom
          offers flexible options to fit your specific needs. With a
          comprehensive database of skilled professionals across Charleston, you
          can easily find the perfect match for your establishment.
        </p>
      </div>

      <div className="relative flex flex-col w-full mt-8 lg:flex-row sm:mt-12">
        <div className="flex flex-col w-full lg:max-w-[50%] gap-4 sm:gap-5 px-4 sm:px-8 lg:ml-[10%]">
          {listData.map((elem, ind) => (
            <div
              className="flex flex-col items-center gap-4 sm:flex-row sm:items-start"
              key={ind}
            >
              <img
                src={elem.icon}
                alt=""
                className="w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32"
              />
              <div className="flex-1 px-4 py-2 shadow-lg rounded-xl sm:rounded-l-full">
                <h1 className="mb-2 text-base font-normal text-center sm:text-lg sm:text-left font-Righteous">
                  {elem.title}
                </h1>
                <p className="text-sm text-center sm:text-left font-poppins text-gray-1">
                  {elem.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
        <img
          src="/images/SEO_Section_04_Background.svg"
          alt=""
          className="hidden lg:block absolute right-0 w-[35%] h-auto bottom-0 translate-y-[10%]"
        />
      </div>
    </section>
  );
}

export default SEOLandingSection4;
