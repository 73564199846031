import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function PageHeaderNav({ textColor, hamColor }) {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const NavLinks = [
    { label: "Home", url: "/" },
    { label: "About", url: "/about-us" },
    { label: "Service", url: "/about-us" },
    { label: "Blog", url: "/blogs" },
    { label: "Contact", url: "/contact-us" },
  ];

  const serviceOptions = [
    { label: "For Bartender", url: "/bartending-jobs-in-charleston" },
    { label: "For Establishment", url: "/hire-a-bartender-in-charleston" },
  ];

  const handleServiceClick = (url) => {
    setIsDropdownOpen(false); // Close the dropdown when an option is clicked
    console.log(url);

    navigate(url);
  };
  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="flex items-center justify-between w-full px-4 pt-5 md:px-16">
      <img
        className="w-16 h-14 md:w-24 md:h-20"
        src="./images/barooom.png"
        alt="logo"
      />
      <button
        className="hidden max-sm:block focus:outline-none"
        onClick={handleMenuToggle}
      >
        <svg
          className={`w-6 h-6 ${hamColor || "text-white"}`}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <section className="items-center hidden gap-4 md:flex lg:gap-10">
        {NavLinks.map((ele, ind) =>
          ind !== 2 ? (
            <a
              href={ele.url}
              className={`${
                !textColor ? "text-white" : "textColor"
              } cursor-pointer text-sm lg:text-base`}
            >
              {ele.label}
            </a>
          ) : (
            <div
              className="relative"
              key={ind}
              onMouseEnter={() => setIsDropdownOpen(true)}
            >
              <p
                className={`flex items-center ${
                  !textColor ? "text-white" : "textColor"
                } cursor-pointer text-sm lg:text-base`}
              >
                Service
              </p>

              {isDropdownOpen && (
                <div
                  className="absolute left-0 z-10 text-black bg-white rounded shadow-lg top-8 w-max"
                  onMouseLeave={() => setIsDropdownOpen(false)}
                >
                  {serviceOptions.map((option) => (
                    <p
                      key={option.label}
                      className="px-4 py-2 text-sm cursor-pointer lg:text-base text-nowrap hover:bg-gray-200 hover:rounded-xl"
                      onClick={() => handleServiceClick(option.url)}
                    >
                      {option.label}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )
        )}
      </section>

      <header className="hidden gap-2 md:flex">
        <Link
          to={"login"}
          className="px-3 py-2 text-sm text-white rounded-full lg:px-5 lg:py-3 lg:text-base bg-estab-back font-poppins hover:bg-red-600"
        >
          Login
        </Link>
        <Link
          to={"/signup"}
          className="px-3 py-2 text-sm rounded-full lg:px-5 lg:py-3 lg:text-base bg-yellow-1 font-poppins hover:bg-yellow-600"
        >
          Sign Up
        </Link>
      </header>

      <div
        className={`fixed top-0 left-0 h-screen w-screen bg-white z-50 transition-all duration-300 ease-in-out md:hidden ${
          showMenu ? "opacity-100 translate-x-0" : "opacity-0 translate-x-full"
        }`}
      >
        <div className="flex flex-col items-center px-6 pt-16">
          <button
            className="absolute top-5 right-5 focus:outline-none"
            onClick={handleMenuToggle}
          >
            <svg
              className="w-6 h-6 text-black"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 18L18 6M6 6L18 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>

          {NavLinks.map((ele, ind) =>
            ind != 2 ? (
              <p
                key={ele.label}
                className="my-4 text-base font-medium cursor-pointer"
                onClick={() => {
                  handleMenuToggle();
                  navigate(ele.url);
                }}
              >
                {ele.label}
              </p>
            ) : (
              <>
                <p
                  key={ele.label}
                  className="my-4 text-base font-medium cursor-pointer"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {ele.label}
                </p>
                {isDropdownOpen && (
                  <div className="w-full text-center bg-gray-200 rounded-xl">
                    {serviceOptions.map((option) => (
                      <p
                        key={option.label}
                        className="my-4 text-base font-medium cursor-pointer"
                        onClick={() => handleServiceClick(option.url)}
                      >
                        {option.label}
                      </p>
                    ))}
                  </div>
                )}
              </>
            )
          )}

          <div className="flex flex-col w-full gap-4 mt-8">
            <Link
              to={"login"}
              className="px-5 py-3 text-center text-white rounded-full bg-estab-back font-poppins hover:bg-red-600"
            >
              Login
            </Link>
            <Link
              to={"/signup"}
              className="px-5 py-3 text-center rounded-full bg-yellow-1 font-poppins hover:bg-yellow-600"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeaderNav;
