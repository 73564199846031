import React from "react";
import "./styles.css";
import EarlyRegiForm from "../../components/EarlyRegiForm";
import NewFooter from "../../components/landing/NewFooter";
import PageHeaderNav from "../PageHeaderNav";
import configuration from "../../config/configuration";
import { Helmet } from "react-helmet-async";
function BarooomLandin() {
  return (
    <div class="maincontainer">
      <Helmet>
        <title>
          Barooom: On-Demand Bartender Staffing & Gig Platform | Hire & Get
          Hired
        </title>
        <meta
          name="description"
          content="Barooom connects bars with verified bartenders for seamless staffing. Find skilled bartenders for events or fill staffing gaps. Bartenders, find flexible gigs and get paid securely. Join today!"
        />
        <meta
          name="keywords"
          content="Pub jobs near me, Bar Staff Job near me, bar staff responsibilities, Bartenders temporary jobs, bartenders needed near me, bartender staffing, cocktail makers, hiring bartenders, barmaid jobs, barmen job, hiring for bartenders, Barooom, Bartender Staffing, Bartender Gigs, On-Demand Bartenders, Hire Bartenders, Bartender Jobs, Barooom, Local Bartenders, Community Bartending App, Trusted Bartender Staffing, On-Demand Local Bartenders, Bar Staffing Network, Beginner Bartending, Bartender Career, Distilled Spirits, Wedding Bartending, Bartender Licensing, Bartending Jobs Charleston, Bar Staff Jobs Charleston, Charleston Mixologist Jobs, Charleston Server Jobs, Bar Jobs Charleston SC, Hire Bartenders Charleston, Charleston Bar Staffing, Charleston Event Bartenders, Restaurant Staffing Charleston, Hire Mixologists Charleston, Contact Barooom, Barooom Support, Barooom Inquiries, Bartender Staffing Contact, Barooom Customer Service"
        />
        <link rel="canonical" href="https://barooom.com" />
      </Helmet>
      <div class="section1">
        <div class="bg">
          <PageHeaderNav />
          <div class="linehandler">
            <div class="yellowline"></div>
          </div>
          <div class="sec1midhandler">
            <div class="sec1mid">
              <div class="sec1left">
                <h1>
                  <span>Barooom </span>- The Ultimate Solution for Bars and
                  Bartenders
                </h1>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    margin: 0,
                    fontWeight: "200",
                    color: "white",
                  }}
                >
                  (A Product By The Cottrill Group, Inc.)
                </p>
                <h5 style={{ lineHeight: "4rem" }}>
                  Let’s Revolutionize Your Bar Staffing Solution for Bars and
                  Bartenders!
                </h5>
                <div style={{ display: "flex", gap: "20px" }}>
                  <img
                    src="/images/Get_Apple_Icon.svg"
                    alt=""
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(configuration.appleAppStoreLink, "_blank")
                    }
                    style={{ height: "50px", width: "auto" }}
                  />
                  <img
                    src="/images/Get_Google_Icon.svg"
                    alt=""
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(configuration.googlePlayStoreLink, "_blank")
                    }
                    style={{ height: "50px", width: "auto" }}
                  />
                </div>
              </div>
              <div class="sec1right">
                <img src="/images/sec1right.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section2">
        <div class="sec2mid">
          <div
            class="sec2left"
            style={{ position: "relative", height: "fit-content" }}
          >
            {/* <img src="/images/Red_BG.svg" alt="" className="red_bg_img" /> */}
            <img src="/images/sec2leftimg.png" className="fore_img" alt="" />
          </div>
          <div class="sec2right">
            <h1>
              Are Staffing Shortages and Unreliable Gigs Holding You Back?
            </h1>
            <h5>
              For bars, finding skilled bartenders can be stressful. For
              bartenders, landing the perfect gig can be challenging. Barooom is
              here to revolutionize staffing and gig booking, making the process
              seamless for both bars and bartenders.
            </h5>
            <h3 style={{ lineHeight: "4rem" }}>
              Say Goodbye to Staffing Shortages and Unreliable Gigs
            </h3>
            <button>
              <a
                href="#section7"
                style={{ textDecoration: "none", color: "white" }}
              >
                Join Barooom Now{" "}
              </a>
            </button>
          </div>
        </div>
      </div>
      <div class="section3">
        <div class="sec3bg">
          <div class="sec3handler">
            <h1>Elevate Your Bar or Bartending Career – Join Barooom Today!</h1>
          </div>
        </div>
      </div>
      <div class="section4">
        <div class="sec4handler">
          <div class="sec4top">
            <h1>Key Benefits</h1>
          </div>
          <div class="circletop">
            <div class="circle1 Cyellow">
              <div class="circle11">
                <img src="/images/flex1.png" alt="" />
                <h1>Customized Preferences</h1>
                <h5>
                  Personalize your staffing needs to fit your establishment's
                  unique requirements.
                </h5>
              </div>
            </div>
            <div class="circle1 Cred">
              <div class="circle11">
                <img src="/images/flex2.png" alt="" />
                <h1>Secure Communication</h1>
                <h5>
                  Directly communicate with bartenders through the app's
                  built-in messaging feature.
                </h5>
              </div>
            </div>
          </div>
          <div class="circlebottom">
            <div class="circle1">
              <div class="circle11">
                <img src="/images/flex3.png" alt="" />
                <h1>Effortless Staffing</h1>
                <h5>
                  Find qualified bartenders with a few taps. Browse profiles,
                  view ratings, and hire with confidence
                </h5>
              </div>
            </div>
            <div class="circle1">
              <div class="circle11">
                <img src="/images/flex4.png" alt="" />
                <h1>Real-Time Availability</h1>
                <h5>
                  Whether you need extra hands for a busy weekend or a special
                  event, the Barooom App covers you with the latest information.
                </h5>
              </div>
            </div>
            <div class="circle1">
              <div class="circle11">
                <img src="/images/flex5.png" alt="" />
                <h1>Verified Profiles</h1>
                <h5>
                  All bartenders undergo a rigorous verification process for
                  your peace of mind.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section5">
        <div class="sec5handler">
          <div class="sec5left">
            <div class="sec5head">
              <h1>
                Key Features For <span>Establishments</span>.
              </h1>
            </div>
            <div class="sec5content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec5text">
                <h1>
                  Effortless Staffing:{" "}
                  <span>
                    Quickly find and hire experienced bartenders through our
                    intuitive app. Browse profiles, view ratings, and hire with
                    confidence.
                  </span>
                </h1>
              </div>
            </div>
            <div class="sec5content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec5text">
                <h1>
                  Personalized Matches:{" "}
                  <span>
                    Personalize your staffing needs to fit your establishment's
                    unique requirements. Specify skill sets, experience levels,
                    and certifications to ensure the perfect match for your
                    team.
                  </span>
                </h1>
              </div>
            </div>
            <div class="sec5content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec5text">
                <h1>
                  Real-Time Updates:{" "}
                  <span>
                    Stay informed about bartender availability in real time,
                    ensuring you always have the staff you need.
                  </span>
                </h1>
              </div>
            </div>
            <div class="sec5content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec5text">
                <h1>
                  Secure Messaging:{" "}
                  <span>
                    Communicate directly with potential hires to discuss details
                    and set expectations.
                  </span>
                </h1>
              </div>
            </div>
            <div class="sec5content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec5text">
                <h1>
                  Verified Professionals:{" "}
                  <span>
                    Our rigorous verification process ensures you hire
                    trustworthy and skilled bartenders.
                  </span>
                </h1>
              </div>
            </div>
          </div>
          <div class="sec5right">
            <img src="/images/Testing.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="section6">
        <div class="sec6handler">
          <div class="sec6left">
            <img src="/images/sec6leftimg.png" alt="" />
          </div>
          <div class="sec6right">
            <div class="sec6head">
              <h1>
                Key Features For <span>Bartenders</span>.
              </h1>
            </div>
            <div class="sec6content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec6text">
                <h1>
                  Easy Gig Booking:{" "}
                  <span>
                    Find and book gigs effortlessly, matching your availability
                    and preferences.
                  </span>
                </h1>
              </div>
            </div>
            <div class="sec6content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec6text">
                <h1>
                  Flexible Opportunities:{" "}
                  <span>
                    Choose gigs that fit your lifestyle, whether you’re looking
                    for part-time or full-time work
                  </span>
                </h1>
              </div>
            </div>
            <div class="sec6content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec6text">
                <h1>
                  Build Your Reputation:{" "}
                  <span>
                    Stand out with verified profiles and ratings, attracting top
                    venues.
                  </span>
                </h1>
              </div>
            </div>
            <div class="sec6content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec6text">
                <h1>
                  Direct Communication:{" "}
                  <span>
                    Stay connected with bars and event organizers through our
                    secure messaging system.
                  </span>
                </h1>
              </div>
            </div>
            <div class="sec6content">
              <img src="/images/sec5icon.png" alt="" />
              <div class="sec6text">
                <h1>
                  Reliable Payments:{" "}
                  <span>
                    Get paid securely and on time for your work, without any
                    hassle
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="section7" id="section7">
        <EarlyRegiForm />
      </div> */}
      {/* <div class="section8">
        <div class="sec8bg">
          <div class="sec8content">
            <div class="sec8handler">
              <img src="/images/sec1logo.png" alt="" />
              <div class="sec8flex">
                <h1
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/about-us")}>
                  About Us
                </h1>
                <div class="hr"></div>
                <h1
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/privacy-policy")}>
                  Privacy Policy
                </h1>
                <div class="hr"></div>
                <h1
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/terms-conditions")}>
                  Terms & Conditions
                </h1>
              </div>
              <h2>Subscribe To Our Newsletter</h2>
              <div class="email">
                <input
                  type="text"
                  placeholder="Enter Your Email Address"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <button class="emailbut" onClick={handleSubscribe}>
                  Subscribe
                </button>
              </div>
              <div class="sec8logo">
                <img src="/images/sec8logo.png" alt="" />
              </div>
            </div>
            <div class="line"></div>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                justifyContent: "center",
              }}>
              <h1 style={{ marginBottom: 0 }}>
                Copyright © 2024 barooom. All Rights Reserved.
              </h1>
              <h1>A Product By The Cottrill Group, Inc.</h1>
            </div>
          </div>
        </div>
      </div> */}
      <NewFooter />
    </div>
  );
}

export default BarooomLandin;
