import React, { useEffect } from "react";
import NewFooter from "../components/landing/NewFooter";
import SEOLandingBody from "../components/SEOLanding1/SEOLandingBody";
import SEOLadingSection2 from "../components/SEOLanding1/SEOLadingSection2";
import SEOLandingSection3 from "../components/SEOLanding1/SEOLandingSection3";
import SEOLandingSection4 from "../components/SEOLanding1/SEOLandingSection4";
import SEOLandingSection6 from "../components/SEOLanding1/SEOLandingSection6";
import SEOLandingSection7 from "../components/SEOLanding1/SEOLandingSection7";

function BarLandingSEO() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="no-scrollbar">
      <SEOLandingBody />
      <SEOLadingSection2 />
      <SEOLandingSection3 />
      <SEOLandingSection4 />
      <SEOLandingSection6 />
      <SEOLandingSection7 />
      <NewFooter />
    </main>
  );
}

export default BarLandingSEO;
