import React, { useState } from "react";
import style from "./Footer.module.css";
import { Link, useNavigate } from "react-router-dom";
import { subscribe } from "../../axios/axios";
import { toast } from "react-toastify";
import { ensureCusEmail } from "../../utils/inputFormatter";

const NewFooter = () => {
  const openExternalLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleSubscribe = () => {
    if (!email) {
      toast.error("Enter Email Id!");
      return;
    }
    if (!ensureCusEmail({ value: email })) {
      toast.error("Enter Valid Email ID!");
      return;
    }
    subscribe({ email })
      .then((res) => {
        toast.success(res.data.message);
        setEmail("");
      })
      .catch((err) => toast.error(err.data.message));
  };

  const links = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about-us" },
    { name: "Service for Bartender", path: "/bartending-jobs-in-charleston" },
    {
      name: "Service for Establishment",
      path: "/hire-a-bartender-in-charleston",
    },
    { name: "Blog", path: "/blogs" },
    { name: "Contact", path: "/contact-us" },
  ];

  return (
    <div className="section8">
      <div className="sec8bg">
        <div className="sec8content">
          <div className="sec8handler">
            <img src="/images/sec1logo.png" alt="" />
            <div className="flex flex-wrap justify-center gap-3 pt-2">
              {links.map((elem, ind) => (
                <p
                  key={ind}
                  className={`px-3 text-lg text-white cursor-pointer hover:text-gray-300 transition-colors
                    max-md:text-base max-sm:text-sm max-sm:px-2
                    ${ind < links.length - 1 && "border-r border-gray-400"}`}
                  onClick={() => navigate(elem.path)}
                >
                  {elem.name}
                </p>
              ))}
            </div>
            <h2 className="py-2 font-normal font-poppins max-sm:mt-2">
              Subscribe To Our Newsletter
            </h2>
            <div className="email">
              <input
                type="text"
                placeholder="Enter Your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="emailbut" onClick={handleSubscribe}>
                Subscribe
              </button>
            </div>
            <div className="flex items-center justify-center h-12 gap-5 max-sm:h-8 max-sm:gap-3 max-sm:mb-2">
              <img
                src="/images/Facebook_Icon.svg"
                alt=""
                className="w-auto h-5 transition-opacity cursor-pointer max-sm:h-4 hover:opacity-80"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=61559697518079&mibextid=ZbWKwL",
                    "_blank"
                  )
                }
              />
              <img
                src="/images/Instagram_Icon.svg"
                alt=""
                className="w-auto h-5 transition-opacity cursor-pointer max-sm:h-4 hover:opacity-80"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/barooombartenders?igsh=Z2ZlYTE0a3VianI3",
                    "_blank"
                  )
                }
              />
              <img
                src="/images/X_Icon.svg"
                alt=""
                className="w-auto h-5 transition-opacity cursor-pointer max-sm:h-4 hover:opacity-80"
                onClick={() =>
                  window.open(
                    "https://x.com/barooombartend?t=GaexP7_k4m90rMbDQGUYlg&s=09",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
          <div className="line"></div>
          <div className="flex flex-col w-full px-4 space-y-4 md:px-8 md:space-y-0 md:flex-row md:justify-between md:items-center">
            <div>
              <p className="mb-0 text-sm font-normal text-center text-white md:text-base font-poppins md:text-left">
                Copyright © 2024 barooom. All Rights Reserved.
              </p>
            </div>
            <div>
              <p className="text-sm font-normal text-center text-white md:text-base font-poppins md:text-left">
                A Product By The Cottrill Group, Inc.
              </p>
            </div>
            <div className="flex justify-center gap-4 md:justify-end md:gap-8">
              <p
                className="text-sm text-white transition-opacity cursor-pointer md:text-base font-poppins hover:opacity-80"
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </p>
              <p
                className="text-sm text-white transition-opacity cursor-pointer md:text-base font-poppins hover:opacity-80"
                onClick={() => navigate("/terms-conditions")}
              >
                Terms & Conditions
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
