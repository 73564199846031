import React, { useState } from "react";
import { toast } from "react-toastify";
import { createEarlyRegi, createEarlyRegiBar } from "../../axios/axios";

function EstabLanding06() {
  const [data, setdata] = useState({
    fullName: "",
    email: "",
    phoneNo: "",
    location: "",
    comments: "",
  });

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setdata({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData(data)) {
      createEarlyRegiBar({ data })
        .then((res) => {
          toast.success(res.data.message);
          setdata({
            fullName: "",
            email: "",
            phoneNo: "",
            location: "",
            comments: "",
          });
        })
        .catch((err) => toast.error(err?.message));
    }
  };

  const validateFormData = (data) => {
    let isValid = true;

    if (!data.fullName) {
      toast.error("Full Name is required");
      isValid = false;
      return;
    }

    if (!data.email) {
      toast.error("Email is required");
      isValid = false;
      return;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      toast.error("Email is invalid");
      isValid = false;
      return;
    }

    if (!data.phoneNo) {
      toast.error("Phone Number is required");
      isValid = false;
      return;
    } else if (!/^\d+$/.test(data.phoneNo)) {
      toast.error("Phone Number is invalid");
      isValid = false;
      return;
    } else if (data.phoneNo.length < 10) {
      toast.error("Phone Number must be at least 10 digits long");
      isValid = false;
      return;
    }

    if (!data.location) {
      toast.error("Location is required");
      isValid = false;
      return;
    }

    return isValid;
  };
  return (
    <div className="relative flex flex-col items-center justify-center w-full min-h-screen gap-8 px-4 py-20 bg-center bg-no-repeat bg-cover lg:flex-row lg:gap-3 sm:px-8 lg:px-32 bg-Body-2 lg:py-0">
      <img
        src="/images/Cloud_Section_06.svg"
        alt=""
        className="absolute top-0 object-contain w-full h-auto -translate-y-[10%]"
      />
      <div className="w-full lg:w-1/2">
        <h1 className="text-2xl font-normal leading-loose text-white sm:text-3xl lg:text-4xl font-Righteous">
          Join
          <span className="text-2xl sm:text-3xl lg:text-4xl font-Righteous text-error">
            {" "}
            Barooom{" "}
          </span>
          Today!
        </h1>
        <p className="text-sm font-normal text-white font-poppins sm:text-base">
          If you're ready to take the next step in your bartending or bar staff
          career in Charleston, Barooom is the platform for you. We make it easy
          to find the right job, no matter your skill level or experience.
          Download the Barooom app today and start exploring opportunities that
          fit your lifestyle.{" "}
        </p>
        <p className="mt-4 text-sm font-normal text-white font-poppins sm:text-base">
          With Barooom, your next great job is just a tap away!
        </p>
      </div>
      <div className="w-full lg:w-[45%]">
        <div className="custom-glass-form">
          <form className="px-4 py-6 bg-white sm:px-5 sm:py-8 rounded-xl">
            <h1 className="text-xl font-normal text-center sm:text-2xl font-Righteous">
              Sign Up Now for Early Access!
            </h1>
            <div className="flex items-center justify-center w-full">
              <div className="w-24 sm:w-32 h-[0.2rem] my-3 sm:my-4 rounded-full bg-estab-back" />
            </div>
            <div className="flex flex-col w-full h-full gap-4 sm:gap-5">
              <label>
                <p className="text-sm text-black font-Righteous sm:text-base">
                  Full name <span className="text-error font-Righteous">*</span>
                </p>
                <input
                  style={{ border: "1px solid #CBD6E2" }}
                  type="text"
                  value={data.fullName}
                  name="fullName"
                  onChange={(e) => handleChange(e)}
                  className="w-full px-4 text-sm rounded-full h-9 sm:h-10 sm:text-base"
                />
              </label>
              <label>
                <p className="text-sm text-black font-Righteous sm:text-base">
                  Email Address{" "}
                  <span className="text-error font-Righteous">*</span>
                </p>
                <input
                  style={{ border: "1px solid #CBD6E2" }}
                  type="text"
                  value={data.email}
                  name="email"
                  onChange={(e) => handleChange(e)}
                  className="w-full px-4 text-sm rounded-full h-9 sm:h-10 sm:text-base"
                />
              </label>
              <label>
                <p className="text-sm text-black font-Righteous sm:text-base">
                  Phone Number{" "}
                  <span className="text-error font-Righteous">*</span>
                </p>
                <input
                  style={{ border: "1px solid #CBD6E2" }}
                  type="text"
                  value={data.phoneNo}
                  name="phoneNo"
                  onChange={(e) => handleChange(e)}
                  className="w-full px-4 text-sm rounded-full h-9 sm:h-10 sm:text-base"
                />
              </label>
              <label>
                <p className="text-sm text-black font-Righteous sm:text-base">
                  Location <span className="text-error font-Righteous">*</span>
                </p>
                <input
                  style={{ border: "1px solid #CBD6E2" }}
                  type="text"
                  value={data.location}
                  name="location"
                  onChange={(e) => handleChange(e)}
                  className="w-full px-4 text-sm rounded-full h-9 sm:h-10 sm:text-base"
                />
              </label>
              <label>
                <p className="text-sm text-black font-Righteous sm:text-base">
                  Other Comments
                </p>
                <input
                  style={{ border: "1px solid #CBD6E2" }}
                  type="text"
                  value={data.comments}
                  name="comments"
                  onChange={(e) => handleChange(e)}
                  className="w-full px-4 text-sm rounded-full h-9 sm:h-10 sm:text-base"
                />
              </label>
            </div>
            <button
              className="flex items-center justify-center w-full gap-2 px-2 mt-4 text-sm font-semibold text-white rounded-full h-9 sm:h-10 bg-estab-back font-poppins sm:text-base"
              onClick={(e) => handleSubmit(e)}
            >
              Get Started{" "}
              <img src="/images/arrow.svg" alt="" className="w-4 sm:w-5" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EstabLanding06;
