import React from "react";

function EstabLanding03() {
  const data = [
    {
      icon: "Section_02_01.svg",
      title: "Bartenders",
      description: `Bartenders are the heart of any bar or restaurant. Whether you're skilled in crafting classic cocktails or innovative new drinks, bartending jobs on Barooom offer the chance to showcase your mixology skills. With Barooom, you'll find opportunities in everything from high-end cocktail lounges to local neighborhood bars.`,
    },
    {
      icon: "Section_02_02.svg",
      title: "Mixologists",
      description: `If you're a cocktail expert who enjoys creating complex, flavorful drinks, Barooom is perfect for you. Mixologists can find jobs in upscale venues and high-profile events where creativity and innovation are key. You can display your signature creations, styles, and techniques on your profile to attract the right employers who appreciate your artistry.`,
    },
    {
      icon: "Section_02_03.svg",
      title: "Servers",
      description: `Great service is a crucial part of the bar experience, and with Barooom, servers can find roles in dynamic environments that prioritize customer interaction and hospitality. Whether you're handling food service, managing orders, or ensuring the bar floor runs smoothly, Barooom connects you to bars and restaurants that are looking for enthusiastic and efficient servers.`,
    },
    //Line 2
    {
      icon: "Section_02_04.svg",
      title: "Barbacks",
      description: `Barbacks are essential to keeping the bar running smoothly. If you're a hardworking individual who enjoys supporting bartenders by stocking supplies, cleaning, and ensuring everything behind the bar is in place, Barooom has numerous job opportunities for you. Barbacks play a vital role, and with Barooom, you can find roles that align with your preferred working hours and locations.`,
    },
    {
      icon: "Section_02_05.svg",
      title: "Host/Hostess",
      description: `As a host or hostess, you are the first point of contact for customers. If you have strong communication skills and a welcoming attitude, Barooom can connect you to establishments looking for someone to manage reservations, greet customers, and ensure a smooth flow of service.`,
    },
    {
      icon: "Section_02_06.svg",
      title: "Front of House (FOH) Support",
      description: `Front of House (FOH) staff are responsible for maintaining customer service and handling tasks such as seating, taking orders, and resolving customer inquiries. Barooom offers plenty of FOH job opportunities in bars, restaurants, and event venues, perfect for individuals who excel in multitasking and customer interaction.`,
    },
    {
      icon: "Section_02_07.svg",
      title: "Cashiers",
      description: `Many bars and restaurants need cashiers to handle payments and ensure that transactions are smooth and accurate. If you have experience in handling cash or card payments and are looking for a bar-related job, Barooom can connect you with positions that need reliable and quick-thinking cashiers.`,
    },
    {
      icon: "Section_02_08.svg",
      title: "Bar Managers",
      description: `For experienced professionals who want to take their careers to the next level, Barooom offers opportunities for bar managers. If you have experience managing staff, inventory, and customer service operations, you can find bar manager roles at various establishments across Charleston through Barooom.`,
    },
  ];
  return (
    <div className="flex flex-col items-center w-full px-4 py-4 h-fit sm:px-8">
      <h1 className="text-2xl font-normal text-center sm:text-3xl lg:text-4xl font-Righteous">
        Types of Jobs Available on{" "}
        <span className="font-Righteous text-error">Barooom</span>?
      </h1>
      <p className="pt-3 font-poppins text-gray-1 text-wrap w-full md:max-w-[70%] lg:max-w-[50%] text-center text-sm sm:text-base">
        Barooom isn't just for bartenders! We connect all kinds of bar staff
        with the right establishments. Here's a breakdown of the positions you
        can find through our platform:
      </p>
      <div className="relative flex flex-wrap justify-center w-full gap-4 px-2 mt-16 sm:gap-6 lg:gap-10 sm:px-8 lg:px-20 sm:mt-20 lg:mt-28">
        <div className="h-[80%] absolute w-full bg-Body-1 mt-[10%]"></div>
        {data.map((elem, ind) => (
          <div
            key={`Section_02_${ind}`}
            className="w-full sm:w-[45%] lg:w-[30%] max-w-[492px] bg-white rounded-xl mt-10 z-10 h-auto"
          >
            <div className="h-full -translate-y-[20%] flex flex-col items-center">
              <img
                src={`/images/${elem.icon}`}
                alt="Card Icons"
                className="h-24 sm:h-28 lg:h-36 w-fit"
              />
              <div className="flex flex-col items-center justify-center px-4">
                <h1 className="text-xl font-normal text-center sm:text-2xl font-Righteous">
                  {elem.title}
                </h1>
                <p className="px-2 pt-3 text-sm text-center sm:text-base font-poppins text-gray-1 text-wrap">
                  {elem.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EstabLanding03;
