import React from "react";

function EstabLanding02() {
  return (
    <div className="min-h-[80vh] w-full relative flex flex-col lg:flex-row gap-8 lg:gap-16 px-4 sm:px-8 lg:px-40 justify-center items-center">
      <div className="max-w-md lg:max-w-none">
        <img
          src="/images/EstabLanding_02_Side.svg"
          alt="Bartender_Image"
          className="w-auto h-[15rem] lg:h-auto lg:-translate-y-24"
        />
      </div>
      <div className="w-full pt-4 lg:w-1/2 lg:pt-28">
        <h1 className="text-2xl font-normal sm:text-3xl lg:text-4xl font-Righteous">
          What is <span className="font-Righteous text-error">Barooom?</span>
        </h1>

        <p className="pt-3 text-sm sm:text-base font-poppins text-gray-1">
          Barooom is a one-of-a-kind app that connects bartenders and other bar
          staff with job opportunities across Charleston. From top-tier bars and
          restaurants to local venues, Barooom helps you find gigs that suit
          your expertise, whether you're a seasoned mixologist or just starting
          out as a server. Our platform simplifies the job-hunting process,
          letting you focus on what you do best—delivering excellent service and
          making the bar experience unforgettable for your patrons.
        </p>

        <p className="pt-2 text-sm sm:text-base font-poppins text-gray-1">
          With our user-friendly interface and smart algorithms, you can easily
          find and apply for jobs that match your availability and skills.
          Whether you're seeking full-time, part-time, or freelance work,
          Barooom offers flexibility, allowing you to choose when and where you
          want to work.
        </p>
      </div>
    </div>
  );
}

export default EstabLanding02;
