import React, { useEffect } from "react";
import EstabLanding01 from "../components/SEOLaning2/EstabLanding01";
import NewFooter from "../components/landing/NewFooter";
import EstabLanding02 from "../components/SEOLaning2/EstabLanding02";
import EstabLanding03 from "../components/SEOLaning2/EstabLanding03";
import EstabLanding04 from "../components/SEOLaning2/EstabLanding04";
import EstabLanding05 from "../components/SEOLaning2/EstabLanding05";
import EstabLanding06 from "../components/SEOLaning2/EstabLanding06";
import { Helmet } from "react-helmet-async";
function EstabLandigSEO() {
  return (
    <div>
      <Helmet>
        <title>
          Hire Bartenders Charleston | Staffing for Bars & Events | Barooom
        </title>
        <meta
          name="description"
          content="Hire skilled bartenders in Charleston with Barooom! Find top-quality mixologists, servers, and bar staff for your bar, restaurant, or event. Streamline your hiring process today!"
        />
        <meta
          name="keywords"
          content="Hire Bartenders Charleston, Charleston Bar Staffing, Charleston Event Bartenders, Restaurant Staffing Charleston, Hire Mixologists Charleston"
        />
        <link
          rel="canonical"
          href="https://barooom.com/bartending-jobs-in-charleston"
        />
      </Helmet>
      <main className="no-scrollbar">
        <EstabLanding01 />
        <EstabLanding02 />
        <EstabLanding03 />
        <EstabLanding04 />
        <EstabLanding05 />
        <EstabLanding06 />
        <NewFooter />
      </main>
    </div>
  );
}

export default EstabLandigSEO;
