import React from "react";

function EstabLanding04() {
  const data = [
    {
      title: "Download the Barooom App",
      description:
        "Barooom is available on both iOS and Android platforms. Download the app from your respective app store and create your profile.",
    },
    {
      title: "Set Up Your Profile",
      description:
        "Include details about your experience, certifications, and skills. Upload a professional photo and any references or reviews from previous employers.",
    },
    {
      title: "Browse Jobs",
      description:
        "Once your profile is complete, you can start browsing jobs. Use filters to narrow down your search by location, job type, or availability.",
    },
    {
      title: "Apply for Gigs",
      description:
        "Apply for as many gigs as you like with a single click. Barooom lets you track your applications and communicate with potential employers directly through the app.",
    },
    {
      title: "Start Working",
      description: `Once you're hired, get to work and start building your reputation within the Barooom community.`,
    },
  ];
  return (
    <div className="relative flex flex-col items-center w-full px-4 py-4 pb-16 sm:px-8">
      <h1 className="text-2xl font-normal text-center sm:text-3xl lg:text-4xl font-Righteous">
        How to Get Started with{" "}
        <span className="font-Righteous text-error">Barooom</span>?
      </h1>
      <p className="pt-3 text-sm font-poppins text-gray-1 text-wrap w-full md:max-w-[70%] lg:max-w-[50%] text-center sm:text-base">
        Getting started with Barooom is simple. Just follow these steps:
      </p>
      <div className="flex flex-col w-full pt-10 lg:flex-row">
        <div className="w-full lg:w-[60%]">
          {data.map((elem, ind) => (
            <div
              key={`Section_04_${ind}`}
              className="flex gap-3 px-4 my-5 sm:px-8 lg:px-36"
            >
              <img
                src={
                  ind % 2 == 0
                    ? `/images/Section_04_Red_Dot.svg`
                    : "/images/Section_04_Yellow_Dot.svg"
                }
                alt="Logo"
                className="w-fit h-fit"
              />
              <div>
                <h1 className="text-xl sm:text-2xl leading-10 font-Righteous text-[#2A2A2A]">
                  <span
                    className={`${
                      ind % 2 == 0 ? "text-[#FF3333] " : "text-[#FFCC42]"
                    } text-xl sm:text-2xl leading-10 font-Righteous px-2 sm:px-4`}
                  >
                    {`0${ind + 1}`}
                  </span>
                  {elem.title}
                </h1>
                <p className="text-sm sm:text-base text-gray-1 font-poppins text-wrap max-w-[100%] text-left">
                  {elem.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <img
          src="/images/EstabLanding_04_Side.svg"
          alt="Side Img"
          className="w-auto h-[15rem] lg:h-[80%] mt-8 lg:mt-0 lg:absolute lg:right-0 lg:translate-x-[15%]"
        />
      </div>
    </div>
  );
}

export default EstabLanding04;
