import React from "react";

function NotFoundBody() {
  return (
    <section className="flex flex-col items-center justify-center px-4 py-4">
      <img
        src="/images/NotFoundLogo.svg"
        alt="404 Not Found"
        className="w-full max-w-[300px] md:max-w-[400px] lg:max-w-[500px] h-auto"
      />
      <h1 className="mt-4 text-3xl font-medium text-center md:text-4xl lg:text-6xl font-poppins">
        Page <span className="text-establishment-red">Not</span> Found
      </h1>
    </section>
  );
}

export default NotFoundBody;
