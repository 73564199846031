import React, { useEffect } from "react";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgetPass from "./pages/ForgetPass";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Verify from "./pages/Verify";
import Setup from "./pages/Setup";
import AuthenticatedWithoutSetupLayout from "./layouts/AuthenticatedWithoutSetup";
import NotAuthenticatedLayout from "./layouts/NotAuthenticatedLayout";
import AuthenticatedLayout from "./layouts/AuthenticatedLayout";
import Logout from "./pages/Logout";
import TermCondion from "./pages/TermCondion";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PendingAcceptance1 from "./components/eventDetails/Establishment/eventDetailPendingAcce/PendingAcceptance1";
import VerificationPending from "./pages/VerificationPending";
import AuthenticatedWithPending from "./layouts/AuthenticatedWithPending";
import "./App.css";
import FreqAsk from "./components/settings/Bartender/FreqAsk";
import ChangePassSetting from "./components/settings/Bartender/ChangePassSetting";
import NotifictionSetting from "./components/settings/Bartender/NotifictionSetting";
import SettingMain from "./components/settings/Bartender/SettingMain";
import WithMainLayout from "./layouts/WithMainLayout";
import JobWraper from "./components/dashboard/Establishment/jobPost/JobWraper";
import Profile from "./pages/Profile";
import GigDetails from "./components/Profile/Establishment/GigDetails";
import Edit1 from "./components/Profile/Establishment/EditProfile/Edit1";
import Edit2 from "./components/Profile/Establishment/EditProfile/Edit2";
import Edit3 from "./components/Profile/Establishment/EditProfile/Edit3";
import EditMore from "./components/Profile/Establishment/EditProfile/EditMore";
import AddCardOp from "./components/Earning/Establishment/AddCardOp";
import PaymentOp from "./components/Earning/Establishment/PaymentOp";
import GigDetailsWrap from "./pages/GigDetailsWrap";
import ViewUser from "./pages/ViewUser";
import Setting from "./pages/Setting";
import Chat from "./pages/Chat";
import BartenderDetails from "./pages/BartenderDetails";
import NewEstablishment from "./pages/NewEstablishment";
import EditBarProfileWrapper from "./pages/EditBarProfileWrapper";
import ChatSupportBart from "./components/settings/Bartender/ChatSupportBart";
import BarooomLandin from "./pages/BarLanding/BarooomLandin";
import DeleteAcc from "./pages/DeleteAcc";
import { useDispatch } from "react-redux";
import { checktrue } from "./redux/notificationSlice";
import setupNotificationListener from "./components/NotificaionHandler";
import NotFound from "./pages/NotFound";
import ViewBartendersDetails from "./components/Profile/Establishment/ViewUserProfile";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import BarLandingSEO from "./pages/BarLandingSEO";
import ContactUs from "./pages/ContactUs";
import EstabLandigSEO from "./pages/EstabLandigSEO";
import Onboarding from "./components/StripeOnboarding/Onboarding";
import StripeSuccess from "./components/StripeSuccess/StripeSuccess";
import OneSignal from "react-onesignal";
import configuration from "./config/configuration";
export default function App() {
  console.log("Development Mode Version 1.0.1");
  const dispatch = useDispatch();
  const onNotification = ({ isNotify }) => {
    if (isNotify) {
      dispatch(checktrue(true));
    }
  };

  useEffect(() => {
    setupNotificationListener({ onNotification: onNotification });
    OneSignal.init({
      appId: configuration.oneSignal.appId,
      safari_web_id: configuration.oneSignal.safari_web_id,
      notifyButton: {
        enable: false,
      },
      autoResubscribe: true,
    });
    OneSignal.Notifications.addEventListener("click", (event) => {
      console.log("Notification Clicked", event);
    });
  }, []);

  return (
    <>
      {/* <OrientationModal /> */}
      <Routes>
        {/* <Route path="/" element={<BarooomLandin />} /> */}

        {/* <Route path="/landing-page" element={<BarooomLanding />} /> */}

        {/* {/*  */}
        {/* <Route
          path="/"
          element={
            <NotAuthenticatedLayout>
              <Landing />
            </NotAuthenticatedLayout>
          }
        /> */}

        <Route path="/" element={<BarooomLandin />} />

        <Route path="*" element={<NotFound />} />
        <Route
          path="/stripe-onboarding-success"
          element={
            <>
              <StripeSuccess />
            </>
          }
        />

        <Route path="/terms-conditions" element={<TermCondion />} />

        <Route path="/about-us" element={<AboutUs />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route
          path="/login"
          element={
            <NotAuthenticatedLayout>
              <Login />
            </NotAuthenticatedLayout>
          }
        />

        <Route
          path="/hire-a-bartender-in-charleston"
          element={<BarLandingSEO />}
        />
        <Route
          path="/bartending-jobs-in-charleston"
          element={<EstabLandigSEO />}
        />
        <Route
          path="/login/:gid"
          element={
            <NotAuthenticatedLayout>
              <Login />
            </NotAuthenticatedLayout>
          }
        />

        <Route
          path="/signup"
          element={
            <NotAuthenticatedLayout>
              <SignUp />
            </NotAuthenticatedLayout>
          }
        />

        <Route
          path="/forget-password"
          element={
            <NotAuthenticatedLayout>
              <ForgetPass />
            </NotAuthenticatedLayout>
          }
        />

        <Route
          path="/dashboard/*"
          element={
            <AuthenticatedLayout>
              <Dashboard />
            </AuthenticatedLayout>
          }
        />
        {/* <Route
          path="/dashboard/chat/*"
          element={
            <AuthenticatedLayout>
              <Chat />
            </AuthenticatedLayout>
          }
        />
        */}
        <Route
          path="/dashboard/chat/*"
          element={
            <AuthenticatedLayout>
              <Chat />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/profile"
          element={
            <AuthenticatedLayout>
              <Profile />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/profile/*"
          element={
            <AuthenticatedLayout>
              <Profile />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/gig/:gid/bartender/:bid"
          element={
            <AuthenticatedLayout>
              <ViewUser />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/bartender-details/:bid"
          element={
            <AuthenticatedLayout>
              <ViewUser />
            </AuthenticatedLayout>
          }
        />

        <Route path="/bartender/:bid" element={<BartenderDetails />} />

        <Route
          path="/establishment-details/:id"
          element={
            <AuthenticatedLayout>
              <GigDetails />
            </AuthenticatedLayout>
          }
        />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:bid" element={<BlogDetails />} />
        <Route path="/delete-account" element={<DeleteAcc />} />

        <Route
          path="/gig/:gid"
          element={
            <AuthenticatedLayout>
              <GigDetailsWrap />
            </AuthenticatedLayout>
          }
        />
        <Route path="/landing" element={<GigDetailsWrap />} />

        <Route
          path="/establishment-details/:id/basic-info"
          element={
            <AuthenticatedLayout>
              <Edit1 />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/establishment-details/:id/more-info"
          element={
            <AuthenticatedLayout>
              <EditMore />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/bartender/profile/:id"
          element={<ViewBartendersDetails />}
        />

        <Route
          path="/StripeOnboarding"
          element={
            <AuthenticatedLayout>
              <Onboarding />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/make-payment/:bid/:jid"
          element={
            <AuthenticatedLayout>
              <PaymentOp />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/make-payment/:bid/:jid/add-card"
          element={
            <AuthenticatedLayout>
              <AddCardOp />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/establishment-details/:id/location"
          element={
            <AuthenticatedLayout>
              <Edit2 />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/establishment-details/:id/images"
          element={
            <AuthenticatedLayout>
              <Edit3 />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/settings"
          element={
            <AuthenticatedLayout>
              <Setting />
            </AuthenticatedLayout>
          }
        />
        {/* 
        <Route
          path="/dashboard/manage-pay-method"
          element={
            <AuthenticatedLayout>
            </AuthenticatedLayout>
          }
        /> */}

        <Route
          path="/dashboard/manage-pay-method/add-card"
          element={
            <AuthenticatedLayout>
              <AddCardOp />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/aboutus"
          element={
            <AuthenticatedLayout>
              <AboutUs />
            </AuthenticatedLayout>
          }
        />

        <Route path="/ticket" element={WithMainLayout(ChatSupportBart)()} />

        <Route path="/faq" element={WithMainLayout(FreqAsk)()} />

        <Route
          path="/change-password"
          element={WithMainLayout(ChangePassSetting)()}
        />

        <Route
          path="/notification-setting"
          element={WithMainLayout(NotifictionSetting)()}
        />

        <Route
          path="/post-job"
          element={
            <AuthenticatedLayout>
              <JobWraper />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/SettingMain"
          element={
            <AuthenticatedLayout>
              <SettingMain />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/ChangePassSetting"
          element={
            <AuthenticatedLayout>
              <ChangePassSetting />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/NotifictionSetting"
          element={
            <AuthenticatedLayout>
              <NotifictionSetting />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/verify"
          element={
            <NotAuthenticatedLayout>
              <Verify />
            </NotAuthenticatedLayout>
          }
        />

        <Route
          path="/verification-pending"
          element={
            <AuthenticatedWithPending>
              <VerificationPending />
            </AuthenticatedWithPending>
          }
        />

        {/* <Route
          path="/setup"
          element={
            <AuthenticatedWithoutSetupLayout>
              <Setup />
            </AuthenticatedWithoutSetupLayout>
          }
        /> */}

        <Route
          path="/new-establishment-setup"
          element={
            <AuthenticatedLayout>
              <NewEstablishment />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/establishment/:id"
          element={
            <AuthenticatedLayout>
              <PendingAcceptance1 />
            </AuthenticatedLayout>
          }
        />

        <Route
          path="/profile/bartender-edit"
          element={
            <AuthenticatedLayout>
              <EditBarProfileWrapper />
            </AuthenticatedLayout>
          }
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
}
