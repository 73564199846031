import React from "react";

function SEOLandingSection3() {
  return (
    <div className="min-h-[80vh] relative flex items-center flex-col w-full">
      <h1 className="px-4 text-2xl font-normal text-center md:text-4xl font-Righteous">
        How Barooom Works in{" "}
        <span className="text-2xl md:text-4xl font-Righteous text-error">
          Charleston
        </span>
      </h1>
      <p className="pt-3 font-poppins text-gray-1 text-wrap w-[90%] md:max-w-[55%] text-center">
        Barooom is designed to streamline the hiring process for bars and
        hospitality establishments throughout Charleston. Whether you're in
        Charleston, Columbia, Myrtle Beach, or Savannah, our platform connects
        you directly with talented bartenders and staff who are ready to provide
        outstanding service.
      </p>
      <section className="relative flex flex-col items-center justify-center px-2 mt-5 w-fit md:px-5 ">
        <div className="flex flex-col w-full md:flex-row md:px-20">
          <div className="flex flex-col items-center justify-center mb-8 max-w-72 md:mb-0">
            <img
              src="/images/01_Create.svg"
              alt="create logo"
              className="w-auto h-16"
            />
            <h3 className="text-xl font-normal font-Righteous">
              01 Create Your Account
            </h3>
            <p className="text-sm text-center font-poppins text-gray-1 text-wrap">
              Sign up on our platform by providing some basic information about
              your establishment.
            </p>
          </div>
          <img
            src="/images/02_Arrow.svg"
            alt="create logo"
            className="hidden ml-48 md:block"
          />
          <div className="flex flex-col items-center justify-center mb-8 max-w-72 md:mb-0">
            <img
              src="/images/03_Review.svg"
              alt="Review logo"
              className="w-auto h-16"
            />
            <h3 className="text-xl font-normal font-Righteous">
              03 Review Applications
            </h3>
            <p className="text-sm text-center font-poppins text-gray-1 text-wrap">
              Browse through applications from qualified bartenders and
              hospitality professionals who are eager to work with you.
            </p>
          </div>
          <img
            src="/images/03_Arrow.svg"
            alt="create logo"
            className="hidden md:block"
          />
        </div>
        <div className="flex flex-col md:flex-row w-full md:w-[85%] md:ml-auto md:-translate-y-5">
          <img
            src="/images/01_Arrow.svg"
            alt="create logo"
            className="hidden md:block"
          />
          <div className="flex flex-col items-center justify-center mb-8 md:ml-20 max-w-72 md:mb-0 md:-translate-x-10">
            <img
              src="/images/02_Post.svg"
              alt="post logo"
              className="w-auto h-16"
            />
            <h3 className="text-xl font-normal font-Righteous">
              02 Post Your Job Listing
            </h3>
            <p className="text-sm text-center font-poppins text-gray-1 text-wrap">
              Describe your specific needs, including the type of event,
              required skills, and number of staff needed.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center md:translate-x-20 md:ml-80 max-w-72">
            <img
              src="/images/04_Connect.svg"
              alt="Connect logo"
              className="w-auto h-16"
            />
            <h3 className="text-xl font-normal font-Righteous">
              04 Connect and Hire
            </h3>
            <p className="text-sm text-center font-poppins text-gray-1 text-wrap">
              Communicate directly with candidates to discuss your expectations
              and finalize your bookings.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SEOLandingSection3;
